/* Fonts */
:root {
  --font-default: "Nunito Sans",Helvetica,Arial,sans-serif;
  --font-primary: "Nunito Sans",Helvetica,Arial,sans-serif;
  --font-secondary: "Nunito Sans",Helvetica,Arial,sans-serif;
  /* --font-default: system-ui, sans-serif;
  --font-primary: system-ui, sans-serif;
  --font-secondary: system-ui, sans-serif; */
}
/* Colors */
:root {
  --color-default: #212529;
  --color-primary: #28ace2;
  --color-secondary: #37373f;
  --color-default-rgb: 33, 37, 41;
  --color-background: #ffffff;
  --color-background-rgb: 255, 255, 255;
  --color-primary-rgb: 232, 69, 69;
  --color-secondary-rgb: 50, 53, 58;
  --color-box-background: #ffffff;
  --color-box-background-rgb: 255, 255, 255;
  --color-inverse: #ffffff;
  --color-inverse-rgb: 255, 255, 255;
  --header-height: 56px;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
  word-wrap: break-word;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  color: #72bf44;
  text-decoration: none;
}
.dropdown-toggle::after {
  display: none!important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
}
.bg-light {
  background-color: #fff!important;
  border-bottom: 1px solid #efefef!important;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15), inset 0 -1px 0 rgba(255,255,255,0.15);
}
.text-left {
  text-align: left!important;
}
.font-weight-bold {
  font-weight: bold!important;
}
.header .btn.btn-primary{
  font-size: 14px;
  padding: 9px 19px;
  margin-left: 30px;
}
.btn.btn-primary{
    font-size: 16px;
    border-radius: 50px;
    color: #fff;
    background: var(--color-primary);
    border-color: var(--color-primary);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s;
}

.btn.btn-primary:hover{
  background: rgba(40, 172, 226, 0.8);
  border-color: rgba(40, 172, 226, 0.8);
}

.section-bg {
  background-color: #eee;
}

section {
  padding: 50px 0;
  overflow: hidden;
}
.section-header {
  text-align: center;
  padding-bottom: 30px;
}
.section-header p {
  margin: 0;
  font-size: 32px!important;
  font-weight: 800;
  font-family: var(--font-primary);
}
.section-header p span {
  /* color: var(--color-primary); */
}

/*Tabs*/
.tour-tabs .nav-tabs {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    max-width: 100%;
    position: relative;
    flex-wrap: unset;
    border: 0;
}
.tour-tabs .nav-tabs::-webkit-scrollbar, .icon-section .icon-row::-webkit-scrollbar, .chip::-webkit-scrollbar{
  display: none;
}
.tour-tabs .nav-tabs li {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tour-tabs .nav-tabs li .nav-link{
    background-color: #fff;
    color: #666;
    border-color: #999;
    padding: 12px 15px;
    border-radius: 24px;
    border-bottom-width: 1px;
    margin: 0 5px;
}
.tour-tabs .nav-tabs li .nav-link.active, .tour-tabs .nav-tabs li .nav-link.active:hover {
  background-color: #28ace2;
  color: #fff;
  padding: 12px 15px;
  border-radius: 24px;
  border-color: #28ace2;
}
.tour-tabs .nav-tabs li .nav-link:hover {
  color: var(--color-primary);
  border-color: #28ace2;
}

@media (max-width: 767px){
  .order-sm-1 {
      order: 1!important;
  }
  .order-sm-2 {
      order: 2!important;
  }
  .scroll-to-top {
    display:none!important;
  }
  .about p, .terms-conditions p, .terms-conditions ol, .terms-conditions ul {
      font-size: 16px!important;
      line-height: 26px!important;
      text-align: left;
  }
  .text-md-center{
    text-align:center!important;
  }
}

.about p{
  font-size:18px;
  line-height: 30px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .nav-pills .nav-link:hover {
  color: #fff;
  background-color: #28ace2;
}

.nav-pills .nav-link::before {
  background-color: transparent;
}

.nav-pills .nav-link {
  margin-bottom: 7px;
  font-size: 16px;
}

.google-login button{
width: 100%;
position: relative;
padding: 3px 24px!important;
border-radius: 100px!important;
color: #212529!important;
border: 1px solid #000!important;
background-color: #fff!important;
display: flex!important;
align-items: center!important;
justify-content: center!important;
}
.google-login button:hover{
  box-shadow: 0px 2px 8px rgba(0,0,0,.16)!important;
}
.google-login button span{
  font-size:16px!important;
  font-weight:700!important;
  line-height:24px!important;
}
