/*fonts */
/* NunitoSans.css */

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Fonts/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Fonts/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('./Fonts/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

/*fonts */
/*--------------------------------------------------------------
# scroll-top 
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

.scroll-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.scroll-top:hover {
  background: rgba(40, 172, 226, 0.8);
  color: #fff;
}

.scroll-to-top {
  right: 15px !important;
  bottom: 15px !important;
  border-radius: 50px !important;
  width: 44px !important;
  height: 44px !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.scroll-to-top:active {
  transform: none !important;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader,
.preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
  width: 100%;
  height: 100vh;
}

#preloader::before,
#preloader::after {
  content: "";
  position: absolute;
  border: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader::after {
  animation-delay: -0.5s;
}

@keyframes animate-preloader {
  0% {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    opacity: 1;
  }

  100% {
    width: 72px;
    height: 72px;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header .logo img {
  max-height: 50px;
  margin-right: 6px;
}

.navbar-nav {
  align-items: center;
}

a.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-secondary);
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

a.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.user-profile a.nav-link::before {
  visibility: hidden !important;
}

.user-profile .dropdown-menu.show {
  margin-top: -4px;
}

a.nav-link:hover::before {
  visibility: visible;
  width: 100%;
}

a.nav-link .dropdown a {
  visibility: visible;
  display: block;
  position: absolute;
  left: 28px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

a.nav-link .dropdown:hover>a {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

nav {
  height: 56px;
}

/* Define the transition effect */
nav .fixed-top {
  transition: all 0.3s ease;
}

.offcanvas-body .btn.btn-primary {
  border-radius: 50px;
}

.navbar .dropdown a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 700;
}

.navbar .nav-item {
  font-family: var(--font-secondary);
  font-size: 14px;
  text-transform: none;
  font-weight: 700;

}

.navbar .dropdown a:focus {
  background: none;
}

.navbar .dropdown a:hover {
  color: var(--color-primary);
}

.nav-item.dropdown .bi-person-circle {
  font-size: 30px;
}

@media (max-width: 768px) {
  .modal-footer .btn.btn-primary {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .justify-content-between.modal-footer {
    display: block !important;
  }

  .justify-content-between.modal-footer .d-grid {
    justify-items: center;
  }

  .header {
    height: 45px
  }

  .sign-up-form .btn.btn-primary {
    /* width: auto!important;
      height: 38px!important; */
  }

  nav {
    height: 56px;
  }

  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .header .logo img {
    max-height: 45px;
  }

  .footer .social-links a {
    margin: 0 5px !important;
  }

}

.sign-up-form .btn.btn-primary {
  margin-left: 0;
  width: 58px;
  height: 48px;
}

@media (max-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
    border: none;
  }
}

@media (max-width: 1200px) {
  .navbar>.container {
    margin-top: -3px;
  }

  .navbar-brand {
    padding-top: 0px !important;
    padding-bottom: 0 !important;
  }

  .offcanvas-body .btn.btn-primary {
    width: 150px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .navbar-toggler,
  .navbar-toggler:focus {
    border: none;
    font-size: 1rem;
    box-shadow: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .offcanvas .offcanvas-body {
    align-items: baseline !important;
  }
}

.hero-sticky .sign-up-form {
  border-radius: 50px;
  background: #fff;
  border: 1px solid #dee2e6;
  width: 350px;
}

.hero-sticky .sign-up-form .form-control {
  border: none;
  border-radius: 50px;
}

.hero-sticky .sign-up-form .form-control:active,
.hero-sticky .sign-up-form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background: #fff !important;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25) !important;
  transition: 0.3s !important;
  border-radius: 4px !important;
  border: none !important;
}

.navbar-nav .dropdown-menu {
  position: static;
  background: #fff !important;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25) !important;
  transition: 0.3s !important;
  border-radius: 4px !important;
  border: none !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .offcanvas {
    flex-grow: 0 !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 16px;
  }

  .dropdown-item {
    min-width: 200px;
  }
}

@media (max-width: 768px) {
  .hero-sticky .sign-up-form {
    width: 280px;
    height: 40px;
  }

  .hero-sticky .sign-up-form .form-control {
    height: 38px;
  }

  .hero.sub-page {
    height: 350px !important;
    min-height: 350px !important;
    padding: 30px 0 !important;
    align-items: center !important;
  }
}

@media (max-width: 1024px) {
  .hero .sign-up-form {
    padding: 3px !important;
  }
}

/*--------------------------------------------------------------
# Home Banner
--------------------------------------------------------------*/
.hero {
  --color-default: #ffffff;
  --color-default-rgb: 255, 255, 255;
  --color-background: #000000;
  --color-background-rgb: 0, 0, 0;
  width: 100%;
  padding: 150px 0 60px 0 !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  height: calc(56px + 560px);
}

.hero::before {
  content: "";
  background: rgba(var(--color-background-rgb), 0.5);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
}

.banner-image.active {
  opacity: 1;
}

.banner-image.inactive {
  z-index: -1;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 40px;
  font-weight: 800;
}

.hero p {
  color: #fff;
  margin: 5px 0 0 0;
  font-size: 18px;
}

.hero .sign-up-form {
  margin-top: 20px;
  padding: 6px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
}

.hero .sign-up-form .form-control {
  border: none;
  border-radius: 50px;
}

.hero .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.hero .sign-up-form .form-control:active,
.hero .sign-up-form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.hero-sticky .sign-up-form button[type=submit],
.sign-up-form button[type=submit] {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  padding: 8px 20px 10px 20px;
  border-radius: 50px;
  color: #fff;
}

.hero.sub-page {
  height: 460px;
  align-items: flex-start;
}

.hero.sub-page .container {
  height: unset !important;
}

.hero.sub-page h2 span {
  color: #28ace2;
  margin-left: 5px;
  cursor: pointer;
}

h2 span i {
  font-size: 24px;
}

.hero .contact {
  margin-top: 8%;
}

.contact .info-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  font-size: 22px;
  line-height: 0;
  color: #fff;
  background: var(--color-primary);
  border-radius: 50%;
  margin-right: 15px;
}

.contact .info-item h3 {
  font-size: 16px;
  color: #fff;
  font-weight: 800;
  margin: 0 0 5px 0;
}

.contact .info-item p {
  padding: 0;
  margin: 0;
  line-height: 22px;
  font-size: 14px;
  color: #fff;
}

.contact .slick-dots li.slick-active button::before {
  opacity: 1;
  color: #28ace2;
}

.contact .slick-dots li button::before {
  color: #fff;
  opacity: 1;
}

.contact .slick-dots li {
  width: 5px !important;
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 30px;
  }

  .hero {
    height: 575px;
    min-height: 575px;
  }

  .hero p {
    font-size: 16px;
  }

  .contact .info-item h3 {
    font-size: 14px;
  }

  .hero .contact {
    margin-top: 15%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 10px 0;
  background: rgba(55, 55, 63, 0.05);
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #676775;
  content: "/";
}

.breadcrumbs a {
  color: var(--color-primary);
  text-decoration: none;
}

.breadcrumbs a:hover {
  color: #72bf44;
  text-decoration: none;
}

@media (max-width: 992px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs h2 {
    margin-bottom: 10px;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# IconBox
--------------------------------------------------------------*/
.icon-box {
  background: #fff;
  padding: 20px;
  width: 100%;
  grid-template-columns: 60px 1fr;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgba(55, 55, 63, 0.1);
  box-shadow: 0 4px 16px rgba(33, 37, 41, 0.1);
  transition: 0.3s;
  cursor: pointer;
}

.icon-box:hover {
  transform: scale(1.08);
}

.icon-box i {
  color: var(--color-primary);
  font-size: 22px;
  background: rgb(40 172 226 / 10%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
}

.icon-box h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px 0;
  font-family: var(--font-secondary);
}

.icon-box p {
  font-size: 14px;
  margin-bottom: 0;
  color: #6c757d;
}

/*--------------------------------------------------------------
# Destination Box
--------------------------------------------------------------*/
.destination {
  border-radius: 5px;
  box-shadow: 0 4px 16px rgba(33, 37, 41, 0.1);
  transition: 0.3s;
}

.destination:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(55, 55, 63, 0.15);
}

.destination button {
  padding: 0;
  border: none;
}

.destination a {
  text-decoration: none;
}

.destination .tour-img img {
  object-fit: cover;
  height: 250px;
  overflow: hidden;
}

.destination .tour-info {
  padding: 12px 15px;
  background-color: #fff;
}

.destination .tour-info h4 {
  font-weight: 800;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  margin-bottom: 0;
  color: var(--color-secondary);
}

/*--------------------------------------------------------------
# Image Banner
--------------------------------------------------------------*/
.image-banner {
  position: relative;
  width: 100%;
  height: 180px;
}

.image-banner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.image-banner:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, .3);
}

.image-banner img {
  position: absolute;
  width: 100%;
  height: 180px;
  object-fit: cover;
  left: 0;
}


.image-banner .button {
  position: absolute;
  width: 100%;
  left: 0;
  top: 47%;
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.image-banner .button a {
  color: #fff;
  background: var(--color-primary);
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  transition: 0.3s;
  z-index: 1;
}

.image-banner:hover .button {
  opacity: 1;
}

/*--------------------------------------------------------------
# Coupon Card
--------------------------------------------------------------*/
.coupon-card {
  background: #f2f8fa;
  padding: 12px;
  border-radius: 8px;
  position: relative;
}

.coupon-design {
  --img-width: 115px;
  position: relative;
  display: grid;
  grid-template-columns: var(--img-width) minmax(200px, 540px) auto;
  grid-gap: var(--space-6);
  gap: var(--space-6);
  justify-content: center;
  align-items: center;
}

.coupon-design img {
  width: 100px;
  height: 155px;
}

.coupon-card h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

.coupon-card p {
  font-size: 15px;
  margin-bottom: 0;
}

.coupon-card a {
  color: var(--color-primary);
  text-decoration: none;
}

.coupon-card a:hover {
  color: #72bf44;
  text-decoration: none;
}

.coupon-row {
  margin: 25px auto;
}

#cpnCode {
  border: 1px dashed #fff;
  padding: 10px 20px;
  border-right: 0;
}

#cpnBtn {
  border: 1px solid #000;
  border-radius: 4px;
  padding: 10px 20px;
  color: #000;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ask-faq .coupon-design img {
  width: 120px;
  height: auto;
}

.ask-faq .coupon-design {
  grid-gap: 20px;
  gap: 20px;
}

@media (max-width: 767px) {
  .discount-code {
    margin: 0;
    grid-column: 2;
    width: fit-content;
  }

  .coupon-design img {
    width: 75px;
    height: 118px;
  }

  .ask-faq .coupon-design img {
    width: 75px;
    height: auto;
  }

  .ask-faq .coupon-design {
    grid-gap: 0px;
    gap: 10px;
  }
}

/*--------------------------------------------------------------
# Tour list
--------------------------------------------------------------*/
.contact .slick-initialized .slick-slide {
  padding: 5px;
}

.slick-initialized .slick-slide {
  padding: 10px;
}

.wsk-cp-product a {
  color: #212527;
  text-decoration: none;
}

.wsk-cp-product {
  background: #fff;
  border-radius: 6px;
  position: relative;
  will-change: transform, filter;
  transition: transform .2s, filter .2s;
}

.wsk-cp-product:hover {
  transform: translateY(-6px);
}

.wsk-cp-img {
  top: 5px;
  width: 100%;
}

.wsk-cp-img img {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  overflow: hidden;
  height: 180px;
}

.list-view .wsk-cp-text .title-product h3 {
  font-size: 16px;
}

.wsk-cp-text {
  padding: 20px 10px;
}

.wsk-cp-text .title-product h3 {
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.wsk-cp-text .description-prod p {
  margin: 0;
  font-size: 13px;
}

.wsk-cp-text .description-prod {
  width: 100%;
}

.wsk-cp-product .card-footer {
  padding: 25px 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-footer::after {
  clear: both;
}

.wcf-left {
  display: grid;
  font-size: 13px;
  color: #333;
}

.wcf-right {
  font-size: 14px;
  font-weight: 600;
}

.wcf-right p {
  margin-bottom: 0;
  font-weight: 800;
}

.wcf-right i {
  color: #f99f1b;
}

.price {
  font-size: 14px;
  font-weight: 800;
}

.list-view .price,
.list-view .wcf-right {
  font-size: 16px;
}

a.buy-btn {
  display: block;
  color: #28ace2;
  text-align: center;
  font-size: 18px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #28ace2;
  transition: all 0.2s ease-in-out;
}

a.buy-btn:hover,
a.buy-btn:active,
a.buy-btn:focus {
  border-color: #28ace2;
  background: #28ace2;
  color: #fff;
  text-decoration: none;
}

.wsk-btn {
  display: inline-block;
  color: #28ace2;
  text-align: center;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
  border-color: #28ace2;
  background: #28ace2;
  padding: 12px 30px;
  border-radius: 27px;
  margin: 0 5px;
}

.wsk-btn:hover,
.wsk-btn:focus,
.wsk-btn:active {
  text-decoration: none;
  color: #fff;
}

.red {
  color: #F44336;
  font-size: 22px;
  display: inline-block;
  margin: 0 5px;
}

/*list view*/
.list-view .wsk-cp-product a {
  display: flex;
  grid-gap: 15px;
}

.list-view .wsk-cp-product .wsk-cp-img {
  top: 0;
  position: relative;
  left: 0%;
  padding: 0px;
  width: 40%;
  transform: translate(0%);
  -webkit-transform: translate(0%);
}

.list-view .wsk-cp-product .wsk-cp-img img {
  box-shadow: none;
  height: 228px;
  object-fit: cover;
}

.list-view .wsk-cp-text {
  width: 60%;
  display: grid;
  align-content: space-between;
}

.list-view .wsk-cp-text .title-product {
  text-align: left;
}

.list-view .wsk-cp-text .title-product h3 {
  font-size: 16px;
}

.list-view .wsk-cp-text .description-prod {
  text-align: left;
}

.list-view .card-footer {
  border-top: none;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  font-size: 14px;
  background-color: #1f1f24;
  padding: 50px 0;
  color: rgba(255, 255, 255, 0.7);
  border-top: 3px solid #28ace2;
  min-height: 313px;
}

.footer .icon {
  margin-right: 15px;
  font-size: 24px;
  line-height: 0;
  color: #28ace2;
}

.footer h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.footer .footer-links {
  margin-bottom: 30px;
}

.footer .footer-links i.icon {
  width: 25px;
  height: 25px;
}

.bi-chevron-right::before {
  width: 14px;
  height: 14px;
}

.nav-item.dropdown i {
  display: inline-block;
  width: 14px;
  height: 19px;
}

.bi-chevron-down::before {
  width: 14px;
  height: 19px;
}

.footer .footer-links p {
  margin-bottom: 0.5rem;
}

.bi-linkedin::before,
.bi-instagram::before,
.bi-facebook::before,
.bi-twitter::before {
  width: 16px;
  height: 24px;
}

.bi-searc::before {
  width: 16px;
  height: 16px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.footer-links a:hover {
  color: #fff;
}

.footer .footer-links ul li {
  padding: 0 0 8px;
}

.footer .footer-links ul li i {
  padding-right: 3px;
  color: #28ace2;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #fff;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  margin: auto;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #fff;
  border-color: #28ace2;
  background-color: #28ace2;
}

.footer .social-links a button {
  background-color: transparent;
  color: #fff;
  border: none;
}

.footer .copyright {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .credits {
  padding-top: 4px;
  text-align: center;
  font-size: 13px;
}

.footer .credits a {
  color: #fff;
}

/*--------------------------------------------------------------
# Swiper slider
--------------------------------------------------------------*/
.home-slider .swiper-slide {
  background-size: cover;
  background-position: center;
  min-height: 600px;
  padding: 30px;
}

.home-slider .swiper-slide::before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  inset: 0;
}

.swiper-pagination-bullet {
  background: #d1d1d7 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #28ace2 !important;
}

.swiper-pagination {
  margin-top: 20px;
  position: relative !important;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.bg {
  width: 100%;
  height: inherit;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.swiper-slide h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #fff;
  position: relative;
}

.swiper-slide .description {
  margin-bottom: 0;
  color: #fff;
  position: relative;
}

/*--------------------------------------------------------------
# Terms Conditions
--------------------------------------------------------------*/
.terms-conditions ol li,
.terms-conditions ul li {
  margin-bottom: 20px;
}

.terms-conditions p {
  font-size: 18px;
  line-height: 30px;
}

.terms-conditions ol,
.terms-conditions ul {
  text-align: justify;
  line-height: 2rem;
  font-size: 1.1146rem;
}

.terms-conditions ol li ul li {
  margin-bottom: 0px;
}

.dropdown:hover .show {
  display: block;
}

/*--------------------------------------------------------------
# Search Result
--------------------------------------------------------------*/
.search-result {
  background-color: #fff;
  margin-top: 6px;
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(33, 37, 41, 0.1);
  z-index: 1;
}

.data-list {
  display: flex;
}

.data-list .loc-desc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-result ul li img {
  height: 50px;
  width: 50px;
  inset: auto;
  position: inherit;
  border-radius: 6px;
}

.search-result ul li:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.search-result ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.search-result ul li {
  padding: 6px 12px;
}

.loc-det {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loc-det p,
#header .loc-det p {
  font-size: 13px;
  color: #6c757d;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loc-det h6,
#header .loc-det h6 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

/* Trailer box ----------------------------------------------------------------------- */

.trailer_box {
  text-align: center;
  line-height: 0
}

.trailer_box img,
.trailer_box a,
.trailer_box,
.trailer_box a::after {
  border-radius: 6px;
  margin: 0 5px;
}

.trailer_box a {
  position: relative;
}

.trailer_box a {
  display: block
}

.trailer_box a::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  background: rgba(0, 0, 0, .15)
}

.trailer_box a::after {
  filter: alpha(opacity=100);
  opacity: 100
}

.trailer_box:hover a::after {
  filter: alpha(opacity=100);
  opacity: 100
}

.trailer_box .desc {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  padding: 20px 15px 35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.trailer_box .desc h2 {
  margin: 0;
  position: relative;
  bottom: 0;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  color: #fff;
}

.trailer_box:hover .desc h2 {
  bottom: 20px
}

.trailer_box .desc .subtitle {
  display: inline-block;
  position: relative;
  bottom: 0;
  line-height: normal;
  letter-spacing: 1px;
  padding: 4px 8px;
  text-transform: uppercase;
  margin-bottom: 15px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
}

.trailer_box:hover .desc .subtitle {
  bottom: 20px
}

.trailer_box .desc .line {
  height: 1px;
  width: 0;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: block
}

.trailer_box:hover .desc .line {
  width: 60%
}

.trailer_box .desc .subtitle {
  background-color: #2991d6;
  font-size: 14px;
}

/* Animation */

.trailer_box a::after,
.trailer_box .desc .subtitle,
.trailer_box .desc .line,
.trailer_box .desc h2 {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

@media (max-width: 768px) {
  .trailer_box .desc h2 {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .trailer_box .desc .subtitle {
    font-size: 12px !important;
  }

  .mobile-bar .sign-up-form .btn.btn-primary {
    width: 58px;
    height: 38px;
  }
}

/* icon-section ----------------------------------------------------------------------- */
.icon-section .icon-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-section .icon-row a {
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-section .icon-box {
  padding: 20px;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 120px;
  height: 135px;
}

.icon-section .icon-box p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #6c757d;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon-box img {
  padding-bottom: 12px;
  height: 50px;
}

@media (max-width: 1200px) {
  .icon-section .icon-box {
    width: 120px;
    min-width: 125px;
  }

  .icon-section .icon-row {
    overflow-x: scroll;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
    padding: 10px;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

/* Modal ----------------------------------------------------------------------- */
.modal-title {
  flex-basis: 95%;
}

.svg-icon .bi-search {
  font-size: 16px;
}

.slick-dots li.slick-active button::before {
  color: #28ace2 !important;
}

/* Forms ----------------------------------------------------------------------- */
.btn-outline-primary,
.btn-outline-primary:hover {
  color: #28ace2;
  border-color: #28ace2;
  margin-right: 5px;
  margin-top: 8px;
}

.payments .btn-outline-primary {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  margin-left: 12px;
}

.btn-check:checked+.btn {
  background-color: #28ace2;
  border-color: #28ace2;
}

.btn-check+.btn:hover {
  color: #fff;
  background-color: #28ace2;
  border-color: #28ace2;
}

.hero .modal form input {
  height: 48px;
  transition: all .5s ease-in;
  border-radius: 0.375rem;
  box-shadow: none;
  font-size: 14px;
}

.select-date input {
  height: 48px;
  transition: all .5s ease-in;
  border-radius: 0.375rem;
  box-shadow: none;
  font-size: 14px;
  background-color: var(--bs-secondary-bg);
}

.form-control:focus {
  border-color: #28ace2 !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #28ace2;
  border-color: #28ace2;
}

.hero .modal form input:focus,
.hero .modal form textarea:focus {
  border-color: #28ace2 !important;
  box-shadow: none !important;
}

button {
  border-radius: 4px;
}

.ps-12 {
  padding-left: 3rem !important;
}

.badge {
  margin: 5px 5px 5px 0;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 767px) {
  .badge {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# checkout Section
--------------------------------------------------------------*/
.checkout .info-item {
  background: #f4f4f4;
  padding: 30px;
  height: 100%;
}

.checkout h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  margin-bottom: 20px;
}

.text-divider {
  line-height: .1em;
  background-color: #28ace2;
  margin: calc(4px*2) 0;
  margin-top: 36px;
}

.text-divider span {
  background-color: #fff;
  padding: 0 7px;
}

.checkout-sidebar h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.checkout .info-item .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: #fff;
  background: var(--color-primary);
  border-radius: 50%;
  margin-right: 15px;
}

.checkout .info-item h3 {
  font-size: 20px;
  color: #6c757d;
  font-weight: 700;
  margin: 0 0 5px 0;
}

.checkout .info-item p {
  padding: 0;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
}

.checkout .info-item .social-links a {
  font-size: 24px;
  display: inline-block;
  color: rgba(55, 55, 63, 0.7);
  line-height: 1;
  margin: 4px 6px 0 0;
  transition: 0.3s;
}

.checkout .info-item .social-links a:hover {
  color: var(--color-primary);
}

.checkout form input[type=submit],
.promocode {
  background-color: #72bf44;
  border-color: #72bf44;
  color: #fff;
}

.checkout form .form-group {
  padding-bottom: 20px;
}

.checkout form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.checkout form .error-message br+br {
  margin-top: 25px;
}

.checkout form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.checkout form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.checkout form .loading::before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  animation: animate-loading 1s linear infinite;
}

.checkout form input:focus,
form input:focus,
form select:focus,
.checkout form textarea:focus,
form textarea:focus {
  border-color: #28ace2 !important;
  box-shadow: none !important;
}

.checkout form input,
form input,
form select {
  height: 48px;
  transition: all .5s ease-in;
  border-radius: 0.375rem;
  box-shadow: none;
  font-size: 16px;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  height: 38px;
}

.checkout form textarea {
  padding: 10px 12px;
}

.checkout form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.4s;
}

.checkout form button[type=submit]:hover {
  color: #fff;
  background: rgba(40, 172, 226, 0.8);
  cursor: pointer;
}

.checkout .checkout-link-box {
  background-color: #f7f7f7;
  padding: 16px 12px;
}

.checkout .checkout-link-box p {
  padding-left: 5px;
  margin-bottom: 0;
}

.checkout .checkout-form {
  margin-top: 30px;
}

.checkout .form-check-input {
  height: 15px;
  width: 15px;
}

.checkout .checkout-payicon {
  margin-top: 30px;
}

.checkout form input:hover {
  border: 1px solid #28ace2;
}

.checkout-payicon img {
  width: 50px;
}

.checkout-sidebar {
  background: #fff;
  box-shadow: 0 4px 16px rgba(var(--color-default-rgb), 0.1);
  padding: 30px;
}

.checkout-table table {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  width: 100%;
}

.checkout-table table thead tr th {
  border-right: medium none;
  font-size: 16px;
  padding: 15px 0;
}

.checkout-table table thead tr .checkout-th-date-txt {
  text-align: left;
  font-weight: 400;
}

.checkout-table table thead tr .checkout-th-date {
  text-align: right;
  font-weight: bold;
}

.checkout-table table tbody tr td {
  padding: 15px 0;
}

.checkout-table table tbody tr .checkout-tb-age {
  text-align: left;
}

.checkout-table table tbody tr .checkout-tb-age-txt {
  text-align: right;
}

.checkout-table table thead,
.checkout-table table tbody {
  border-bottom: 1px solid #ebebeb;
}


.checkout-table table tfoot tr td {
  padding: 15px 0;
}

.checkout-table table tfoot tr .checkout-tf-pricetxt {
  font-weight: bold;
  text-align: left;
}

.checkout-table table tfoot tr .checkout-tf-price {
  font-weight: bold;
  text-align: right;
  font-size: 18px;
}

.checkout-table table tfoot tr td span {
  font-weight: normal;
  font-size: 12px;
}

@media (max-width: 767px) {
  .checkout-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 12px 0;
    z-index: 1;
  }
}


/*--------------------------------------------------------------
# Blog Details Page
--------------------------------------------------------------*/
.blog-details .sidebar {
  background-color: var(--color-box-background);
  padding: 24px 16px;
  box-shadow: 0 4px 16px rgba(var(--color-default-rgb), 0.1);
}

.blog-details .sidebar.sticky-top {
  animation: slideDown 0.5s ease-in-out;
  top: calc(56px + calc(4px*8));
}

.blog-details .sidebar .sidebar-title {
  color: var(--color-secondary);
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  padding: 0;
  margin: 0;
}

.blog-details .article {
  background-color: #fff;
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  padding: 0;
}

.blog-details .content h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: rgba(33, 37, 41, 0.05);
  padding: 30px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  line-height: 40px;
  margin-bottom: 0;
  font-weight: 800;
  font-size: 24px;
}

.blog-details .content blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--color-primary);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details h3,
.blog-details .content h3 {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

.sidebar .button-theme {
  display: block;
  margin-left: 0;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
}

.sidebar .share-box {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
}

.sidebar .share-box i {
  color: #28ace2;
}

.sidebar .share-box p {
  margin-bottom: 0;
  padding: 8px 12px;
}

.sidebar .share-box p:hover {
  color: #28ace2;
  cursor: pointer;
}

.sidebar .share-box p a {
  color: #212529;
}

.sidebar .share-box p a:hover,
.sidebar .share-box p a:focus {
  color: #28ace2;
}

.sidebar .share-box p i {
  margin-right: 4px;
}

.sidebar .btn.btn-primary {
  display: block;
  margin-left: 0;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 10px 20px;
  width: 100%;
}

/*--------------------------------------------------------------
# Accordion
--------------------------------------------------------------*/
.accordion-item {
  border: none;
  box-shadow: 0px 5px 25px 0px rgba(var(--color-default-rgb), 0.1);
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
}

.accordion-item .accordion-button {
  padding: 0;
}

.accordion-item .accordion-button:focus {
  border: none;
  box-shadow: none
}

.accordion-item .accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}

.accordion-item .accordion-button {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  color: #343a40;
}

.accordion-item .accordion-body {
  padding: 1rem 0 0;
}

.accordion-item .accordion-body ul {
  margin-bottom: 0;
  line-height: 2;
}

/*--------------------------------------------------------------
# Activity List
--------------------------------------------------------------*/
.activity-lists .card {
  border: 1px solid #28ace2;
  margin-bottom: 30px;
  box-shadow: rgb(186, 186, 186) 0px 3px 5px 0px;
  flex-direction: row;
}

.activity-lists .card .card-footer {
  width: 30%;
  border-left: 1px solid #dee2e6;
}

.activity-lists .card .header-width {
  width: 70%;
}

.activity-lists .card .header-width-addon {
  width: 1000%;
}

.activity-lists .card-header {
  background-color: #e9eaec;
}

.activity-lists .card-footer {
  background-color: #fff;
}

.activity-lists .card-header {
  border-bottom: none;
}

.activity-lists .card-header h6 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.activity-lists .card-footer {
  border-top: 1px solid #dee2e6;
}

.activity-lists .card-footer {
  padding: 0.5rem 1rem;
}

.activity-lists .card-footer {
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.card-footer {
  padding: 25px 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-lists .card-body .card-text {
  display: -webkit-box;
}

.activity-lists .card-footer .card-footer-right,
.activity-lists .card-footer .card-footer-left {
  width: 100%;
}

.activity-lists .card-footer p {
  margin-bottom: 0;
}

.activity-lists .card-footer .btn.btn-primary {
  border-radius: 6px;
  width: 100%;
  margin-left: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff !important;
  text-align: center;
  font-weight: 600;
  padding: 10px 20px;
}

.link {
  color: #28ace2;
  cursor: pointer;
}

.modal-footer .btn.btn-primary {
  width: 150px;
  text-align: center;
  margin-left: 30px;
}

.modal-title.h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 800;
}

.modal-body .alert-info {
  background-color: #F2F8FA;
  border-color: #F2F8FA;
}

.modal-body .alert-info h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.alert.alert-info p {
  margin-bottom: 0;
}

ul.list-ul {
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 767px) {
  .activity-lists .card {
    flex-direction: column;
  }

  .activity-lists .card .header-width,
  .activity-lists .card .card-footer {
    width: 100%;
  }

  .activity-lists .card .header-width-addon,
  .activity-lists .card .card-footer {
    width: 100%;
  }

  .modal-content {
    /* min-height: 97vh; */
    height: calc(100% - 16px);
    border-radius: 24px 24px 0 0;
    position: absolute;
    bottom: 0;
  }

  .modal-dialog {
    position: unset;
    margin: 0;
  }

  .activity-heading {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: calc(4px*6);
    gap: calc(4px*6);
  }

  .mobile-block {
    display: block !important;
  }

  .mobile-none {
    display: none !important;
  }

  .blog-details .title {
    font-size: 20px;
    font-weight: 800;
    line-height: 32px;
  }

  .activity-slider .swiper-slide img {
    width: 100%;
    height: 250px !important;
    object-fit: cover !important;
  }

  .activity-slider {
    min-height: 250px !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.8rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }
}

.mobile-block {
  display: none;
}

.star-rating p {
  font-size: 24px;
}

.star-rating .fa-star {
  color: #f99f1b;
  font-size: 16px;
}

.star-rating p a {
  font-size: 16px;
  text-decoration: underline;
}

.article .price,
.article .star-rating {
  font-size: 18px;
  font-weight: 800;
}

.text-blue {
  color: #28ace2;
}

span.text-blue {
  cursor: pointer;
}

.activity-slider .swiper-slide img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 16px;
}

.time-slot .input-radio-date {
  display: inline-block;
  margin: 0;
}

.time-slot .input-radio-date label {
  width: auto;
  height: auto;
  padding: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.time-slot .input-radio-date label span {
  font-weight: normal;
  font-size: 14px;
}

.input-radio-date {
  display: flex;
  margin: calc(4px*4) 0;
  gap: 8px;
}

.btn-check:checked+.btn {
  background-color: #28ace2;
  border-color: #28ace2;
}

.input-radio-date label span {
  font-weight: bold;
  font-size: 16px;
}

.input-radio-date label {
  display: inline-grid;
  width: 75px;
  height: 75px;
  align-items: center;
  margin-bottom: 5px;
  font-size: 16px;
  background: none;
  color: #666;
  padding: 0;
  align-content: space-evenly;
  border-color: #c4c4c4;
}

.input-radio-date label span.day {
  font-weight: normal;
  font-size: 13px;
  margin-top: -15px;
}

.btn:hover {
  color: #fff;
  background-color: #28ace2;
  border-color: #28ace2;
}

.home-slider .btn.btn-primary {
  position: relative;
  margin-left: 0;
  margin-top: 15px;
}

/*--------------------------------------------------------------
# Preference
--------------------------------------------------------------*/
.qty-body {
  display: block;
}

.time-slot {
  display: flex;
}

.quantity {
  display: block;
  word-wrap: break-word;
  width: 70%;
}

.quantity p {
  word-wrap: break-word;
}

.quantity-details {
  flex-basis: 26%;
  flex-shrink: 0;
}

.quantity-calc {
  flex-basis: 100%;
}

.quantity-calc-list:not(:last-child) {
  margin-bottom: calc(4px*3);
}

.quantity-calc-list {
  padding: calc(4px*4);
  background-color: #f6f6f6;
  border-radius: 8px;
}

.quantity-details h5,
.modal-footer h5,
.time-slot h5 {
  font-size: 18px;
  font-weight: 700;
}

.numberstyle-qty .qty-btn {
  width: 30px;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  transition: all 250ms ease;
}

.numberstyle-qty input,
.numberstyle-qty .qty-btn {
  position: relative;
  display: block;
  float: left;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0px;
  text-align: center;
  line-height: 40px;
  outline: 0;
  box-shadow: none;
}

.numberstyle-qty input,
.numberstyle-qty .qty-btn {
  position: relative;
  display: block;
  float: left;
  height: 40px;
  padding: 0;
  margin: 0;
  border: 0px;
  text-align: center;
  line-height: 40px;
  outline: 0;
  box-shadow: 0px;
}

.numberstyle-qty .qty-btn {
  width: 30px;
  font-size: 18px;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  transition: all 250ms ease;
}

.numberstyle-qty {
  position: relative;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #999;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .blog-details .d-flex.star-rating {
    display: block !important;
  }

  .qty-body {
    flex-direction: column;
  }

  .blog-details h3 {
    font-size: 18px;
    line-height: 26px;
  }

  .mobile-bottom {
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 12px 0 !important;
    border-top: 1px solid #eee;
  }

  .mobile-bottom .btn-primary {
    min-width: 150px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
  }

  .mobile-bottom i {
    font-size: 20px;
  }

  .mobile-bottom .icon.btn-primary {
    background-color: #fff;
    border-color: #fff;
    color: #28ace2;
    min-width: auto;
  }

  .date-selection.sticky-top {
    top: 0;
    z-index: 1020;
    background-color: #fff;
  }

  .input-radio-date label span {
    font-size: 14px;
  }

  .input-radio-date label span.day {
    margin-top: -5px;
  }

  .input-radio-date label {
    width: 60px;
    height: 60px;
    font-size: 14px;
    padding: 5px;
  }

  .activity-lists .card-footer {
    display: block;
  }

  .activity-lists {
    padding-top: 12px;
  }

  .list-view .wsk-cp-product .wsk-cp-img img {
    height: 100% !important;
  }
}

/*--------------------------------------------------------------
# Share Modal
--------------------------------------------------------------*/
.popup .icons a {
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.popup .content {
  margin: 10px 0;
}

.popup .icons {
  margin: 15px 0 20px 0;
  display: flex;
  grid-column-gap: 10px;
  padding-left: 0;
}

.content p {
  font-size: 16px;
}

.content .icons a {
  height: 40px;
  width: 40px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}

.icons a i {
  transition: transform 0.3s ease-in-out;
}

.icons a:nth-child(1) {
  color: #1877F2 !important;
  border-color: #b7d4fb;
}

.icons a:nth-child(1):hover {
  background: #1877F2;
}

.icons a:nth-child(2) {
  color: #46C1F6 !important;
  border-color: #b6e7fc;
}

.icons a:nth-child(2):hover {
  background: #46C1F6;
}

.icons a:nth-child(3) {
  color: #25D366 !important;
  border-color: #bef4d2;
}

.icons a:nth-child(3):hover {
  background: #25D366;
}

.icons a:nth-child(4) {
  color: #0088cc !important;
  border-color: #b3e6ff;
}

.icons a:nth-child(4):hover {
  background: #0088cc;
}

.icons a:hover {
  color: #fff !important;
  border-color: transparent;
}

.icons a:hover i {
  transform: scale(1.2);
}

.popup .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content .field {
  margin: 12px 0 -5px 0;
  height: 48px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.popup .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content .field {
  margin: 12px 0 -5px 0;
  height: 48px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.field.active {
  border-color: #28ace2;
}

.field i {
  width: 35px;
  font-size: 18px;
  text-align: center;
}

.field.active i {
  color: #28ace2;
}

.field input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.field button {
  color: #fff;
  padding: 5px 18px;
  background: #28ace2;
  border: none;
}

.field button:hover {
  background: #72bf44;
}

.field button:focus {
  box-shadow: none;
}

.modal-footer {
  flex-wrap: inherit;
}

.sticky-top {
  transform-origin: center;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}


/*--------------------------------------------------------------
# React DatePicker
--------------------------------------------------------------*/
.calendar .react-datepicker {
  border: none;
  width: 100%;
}

.calendar .react-datepicker__month-container {
  width: 50%;
}

.calendar .react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}

.calendar .react-datepicker__day {
  border: 1px solid #999;
  border-radius: 0.3rem;
}

.calendar .react-datepicker__day-name,
.calendar .react-datepicker__day,
.calendar .react-datepicker__time-name {
  width: 36px;
  height: 36px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
}

.calendar .react-datepicker__day.react-datepicker__day--outside-month,
.calendar .react-datepicker__day.react-datepicker__day--outside-month:hover {
  height: 0px !important;
  color: #b2b2b2;
  cursor: default;
  border: none;
}

.calendar .react-datepicker__week {
  margin-bottom: 4px;
}

.calendar .react-datepicker__day--selected,
.calendar .react-datepicker__day--selected:hover,
.calendar .react-datepicker__day:hover {
  background-color: #28ace2;
  color: #fff;
  border: 1px solid #28ace2;
}

.calendar .react-datepicker__day.react-datepicker__day--disabled,
.calendar .react-datepicker__day.react-datepicker__day--disabled:hover {
  border: none;
  background: none;
  color: #ccc;
}

.calendar .react-datepicker__day.react-datepicker__day--today.react-datepicker__day--outside-month {
  border-bottom: none;
}

.calendar .react-datepicker__day.react-datepicker__day--today,
.calendar .react-datepicker__day.react-datepicker__day--today:hover {
  font-weight: normal;
  border-bottom: 1px solid #28ace2;
  border: 1px solid #999;
  border-radius: 0.3rem;
}

.react-datepicker__day-names {
  margin-top: 12px;
}

.calendar .react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

@media (max-width: 767px) {
  .calendar .react-datepicker__month-container {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .calendar .modal-dialog {
    max-width: 100% !important;
  }
}

/*--------------------------------------------------------------
# Search Filters
--------------------------------------------------------------*/
.search-list-icons {
  display: flex;
  overflow-x: auto;
  position: relative;
}

.search-icons .btn-primary,
.search-icons .btn-check+.btn:hover,
.search-icons .btn-check:checked+.btn {
  background-color: transparent;
  border-color: transparent;
  color: #333;
  font-weight: 700;
  font-size: 13px;
}

.search-icons label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.search-icons i:hover,
.search-icons .btn-check:checked+.btn i {
  border: 4px solid #28ace2;
}

.search-icons .btn {
  display: inline-grid;
  justify-items: center;
  gap: 8px;
}

.search-list .wsk-cp-product:hover {
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, .16));
}

.search-icons i {
  box-shadow: 0 4px 16px rgba(var(--color-default-rgb), 0.1);
  border-radius: 50px;
  width: 48px;
  height: 48px;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.list .sidebar {
  background: #fff;
  box-shadow: 0 4px 16px rgba(var(--color-default-rgb), 0.1);
  padding: 16px;
}

.list .sidebar input[type=text],
.list .sidebar input[type=search],
.list .sidebar .selectpicker,
#searchModalToggle .modal-body input[type=text],
#searchModalToggle .modal-body input[type=search],
#searchModalToggle .modal-body .selectpicker,
.list input[type=text],
.list input[type=search],
.list .selectpicker {
  height: 48px;
  transition: all .5s ease-in;
  border-radius: 0.375rem;
  box-shadow: none;
  font-size: 16px;
  margin-bottom: 12px;
}

.mt--12 {
  margin-top: -12px;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check {
  cursor: pointer;
  margin-top: 12px;
}

.range-slider__wrap input {
  width: 100%;
}

.custom-slider .range-slider__track {
  background-color: blue !important;
}

.custom-slider .range-slider__thumb {
  background-color: red !important;
  border-color: red !important;
}

.range-slider__tooltip__label {
  display: none;
}

@media (max-width: 767px) {
  .search-list-icons {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: calc(4px*4);
    padding: calc(4px*4) 0;
    justify-items: center;
  }

  .modal-body h5 {
    font-size: 16px;
  }

  .fixed.fixed-top {
    padding: 10px 0 !important;
    position: sticky;
    background-color: #fff;
    width: 100%;
    top: 0;
    z-index: 999;
  }

  .search-list .wsk-cp-product {
    padding: 0;
  }
}

.fixed-top {
  z-index: 99;
}

.chip .badge {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 35px;
  border-radius: 25px;
  color: inherit;
  background-color: #f1f1f1 !important;
}

.chip .btn-close {
  font-size: 12px;
}

.chip {
  overflow-x: auto;
  white-space: nowrap;
}

.sticky-top {
  z-index: 1;
}

.faq .sticky-top {
  top: calc(56px + calc(4px*8));
}

.active>.page-link,
.page-link.active {
  background-color: #28ace2;
  border-color: #28ace2;
}

.pagination,
.pagination:hover {
  --bs-pagination-color: #28ace2;
}

.page-link:focus {
  box-shadow: none;
}

/*--------------------------------------------------------------
# Our Partners
--------------------------------------------------------------*/
.our-partners .slick-slide img {
  width: 125px;
  height: auto;
  vertical-align: middle;
}

.our-partners .slick-track {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.our-partners .slick-initialized .slick-slide {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact-us .info-container {
  background-color: var(--color-primary);
  height: 100%;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}

.contact-us .info-item {
  width: 100%;
  background-color: #37bdf4;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}

.contact-us .info-item:last-child {
  margin-bottom: 0;
}

.contact-us .info-item i {
  font-size: 20px;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contact-us .info-item h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.contact-us .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contact-us .info-item:hover i {
  background: #fff;
  color: var(--color-primary);
}

.contact-us .contact-form {
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 0 10px 10px 0;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.signip-items i {
  font-size: 28px;
}

.signup-buttons button {
  width: 100%;
  padding: 12px;
  font-weight: 700;
  font-size: 16px;
  border-radius: 50px;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #28ace2 !important;
  border: none;
  border-radius: 0;
}

.nav-tabs .nav-link {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: none;
  border: none;
  border-bottom: 2px solid #fff !important;
}

.nav-link:hover {
  color: #28ace2;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-item a.nav-link {
  justify-content: flex-start;
}

.nav-item a.nav-link i {
  margin-right: 8px;
}

.navbar .dropdown a a {
  padding: 0 !important;
  color: var(--bs-dropdown-link-color);
}

.navbar .dropdown a:hover a {
  color: #28ace2;
}

.wsk-cp-product .wsk-cp-img i {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 50%;
  cursor: pointer;
}

.bi-heart::before {
  content: "\f417";
  vertical-align: middle !important;
}

.bi-heart-fill::before {
  vertical-align: middle !important;
}

/* Default style for hiding the SearchResult */
.search-result-hidden {
  display: none !important;
}

.city-high {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid #fff;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 30px;
  color: var(--color-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 30px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: var(--color-primary);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  margin: 0 15px 0 15px;
  padding: 20px;
  background: #f2f8fa;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
}

.testimonials .testimonial-item p:first-child {
  font-style: italic;
  padding-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 100px;
}

.testimonials .testimonial-item p:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.testimonials .testimonial-item p a {
  color: var(--color-secondary);
}

.testimonials .testimonial-item p a:hover {
  text-decoration: underline;
}

.testimonials .testimonial-item .stars {
  margin: 10px 0 10px 30px;
}

.testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.rating .review-list {
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.rating .review-list h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: var(--color-secondary);
}

.rating .review-list .review-star {
  margin: 0 0 10px 0;
}

.rating .review-list .review-star i {
  color: #ffe11b;
  margin-right: 5px;
}

.rating .review-list .review-date {
  color: #878787
}

.rating .review-list p {
  font-style: italic;
}

.rating .review-list h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  color: #212529;
}

.rating .review-list h6 a {
  color: #212529;
  text-decoration: underline;
}


/* Ticket Designs */
.booking {
  text-align: center;
}

.booking-header {
  margin-bottom: 30px;
}

.booking-header .bheader {
  text-align: -webkit-center;
}

.booking-header .bheader .sign {
  animation: signAni 430ms ease-in 660ms forwards;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

/* * COLOR SPECIFIC */
.fail .sign {
  background-color: rgba(255, 53, 53, 1);
  box-shadow: 0 0 0 15px rgba(255, 130, 130, 1);
}

.fail .sign::before,
.fail .sign::after {
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  content: "";
  display: block;
  height: 40px;
  left: calc(50% - 2px);
  position: absolute;
  top: calc(50% - 20px);
  width: 5px;
}

.fail .sign::before {
  transform: rotate(45deg);
}

.fail .sign::after {
  transform: rotate(-45deg);
}

.success .sign {
  background-color: rgba(78, 196, 94, 1);
  box-shadow: 0 0 0 15px rgba(116, 209, 129, 1);
}

.success .sign::before,
.success .sign::after {
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  content: "";
  display: block;
  height: 40px;
  left: calc(50% - 2px);
  position: absolute;
  top: calc(50% - 20px);
  width: 5px;
}

.success .sign::before {
  left: calc(50% + 5px);
  transform: rotate(45deg);
  top: calc(50% - 20px);
}

.success .sign::after {
  height: 20px;
  left: calc(50% - 15px);
  transform: rotate(-45deg);
  top: calc(50% - 5px);
}

/* * ANIMATIONS */
@keyframes wrapperAni {
  0% {
    opacity: 0;
    transform: scale(0.95) translateY(40px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes headerAni {
  0% {
    border-radius: 0;
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    border-radius: 50%;
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes signAni {
  0% {
    opacity: 0;
    transform: scale(0.3) rotate(180deg);
  }

  60% {
    transform: scale(1.3);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

#element-container {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem;
  box-shadow: none;
  padding: 12px;
  min-height: 200px;
}

#element-container iframe {
  min-height: 180px;
}

.checkout-fixed #element-container {
  border: none;
  min-height: 0;
}

.hot {
  position: relative;
  top: -7px;
  left: -17px;
  border-radius: 0.375rem 0 15px 0;
  box-shadow: rgb(186, 186, 186) 0px 3px 5px;
  padding: 6px 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
}

.hot.blue {
  background-color: #28ace2;
}

.hot.yellow {
  background-color: #f59758;
}

.hot.green {
  background-color: #35d164;
  color: white !important;
}

.hot.pink {
  background-color: #f74b81;
}

.blog-details .testimonials .testimonial-item p:first-child,
.modal-body .testimonials .testimonial-item p:first-child {
  padding-bottom: 20px;
}

.blog-details .testimonials .testimonial-item p,
.modal-body .testimonials .testimonial-item p {
  margin: 0;
}

.blog-details .testimonials .testimonial-item,
.modal-body .testimonials .testimonial-item {
  min-height: 0;
}

.blog-details .testimonials .testimonial-item h3,
.blog-details .testimonials .testimonial-item h4,
.blog-details .testimonials .testimonial-item .stars,
.modal-body .testimonials .testimonial-item h3,
.modal-body .testimonials .testimonial-item h4,
.modal-body .testimonials .testimonial-item .stars {
  margin-left: 20px;
}

.width_100 {
  width: 100px;
}

.react-tel-input .form-control {
  height: 48px !important;
  width: 100% !important;
}


.numberstyle-qty input[type="number"] {
  -moz-appearance: textfield;
  /* For Firefox */
  width: 38px;
}

.numberstyle-qty input[type="number"]::-webkit-inner-spin-button,
.numberstyle-qty input[type="number"]::-webkit-outer-spin-button,
.numberstyle-qty input[type="number"]::-webkit-inner-spin-button:active {
  -webkit-appearance: none;
  /* For Chrome, Safari, Edge */
  -moz-appearance: none;
  margin: 0;
  display: none;
}

.numberstyle-qty input[type="number"]::-moz-number-spin-box {
  display: none;
  /* For Firefox */
}


.payment-loader {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.payment-loader .binding {
  content: '';
  width: 60px;
  height: 4px;
  border: 2px solid #28ace2;
  margin: 0 auto;
}

.payment-loader .pad {
  width: 75px;
  height: 50px;
  border-radius: 8px;
  border: 2px solid #28ace2;
  padding: 6px;
  margin: 0 auto;
}

.payment-loader .chip {
  width: 12px;
  height: 8px;
  background: #28ace2;
  border-radius: 3px;
  margin-top: 4px;
  margin-left: 3px;
}

.payment-loader .line {
  width: 52px;
  margin-top: 6px;
  margin-left: 3px;
  height: 4px;
  background: #28ace2;
  border-radius: 100px;
  opacity: 0;
  -webkit-animation: writeline 3s infinite ease-in;
  -moz-animation: writeline 3s infinite ease-in;
  -o-animation: writeline 3s infinite ease-in;
  animation: writeline 3s infinite ease-in;
}

.payment-loader .line2 {
  width: 32px;
  margin-top: 6px;
  margin-left: 3px;
  height: 4px;
  background: #28ace2;
  border-radius: 100px;
  opacity: 0;
  -webkit-animation: writeline2 3s infinite ease-in;
  -moz-animation: writeline2 3s infinite ease-in;
  -o-animation: writeline2 3s infinite ease-in;
  animation: writeline2 3s infinite ease-in;
}

.payment-loader .line:first-child {
  margin-top: 0;
}

.payment-loader .line.line1 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.payment-loader .line.line2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.payment-loader .loader-text {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
}


@keyframes writeline {
  0% {
    width: 0px;
    opacity: 0;
  }

  33% {
    width: 52px;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes writeline2 {
  0% {
    width: 0px;
    opacity: 0;
  }

  33% {
    width: 32px;
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.green {
  color: green !important;
}

.transform {
  transform-origin: 0 0% !important;
}

.right-0 {
  right: 0 !important;
}

.transition-1 {
  transition: 1s ease-in-out;
}

.hero .container {
  width: 100%;
  height: 366px;
}

.our-destination {
  width: 100%;
  min-height: 570px;
}

/* .recently-viewed{
  width:100%;
  min-height:533px;
} */
.strike-through {
  text-decoration: line-through;
}

.footer-style-1 {
  background: #1f1f24;
  border-top: 4px solid var(--color-primary);
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 13px;
  min-height: 72px;
}

.footer-style-1 a {
  color: #fff;
}

.footer-style-1 a:hover {
  color: var(--color-primary);
}

.footer-style-1 .social-links a:hover,
.footer-style-1 .social-links a button:hover {
  color: #28ace2;
}

.footer-style-1 .social-links a button {
  background: none;
  color: #fff;
  border: none;
}

/* .footer-style-1 .copyright{
  padding-top:20px;
  border-top: 1px solid #666;
} */
.tour-tabs {
  min-height: 628px;
}

.tab-pane {
  min-height: 500px;
}

.booknow {
  min-height: 280px;
  width: 100%;
}

.list-inline li::before {
  content: '|';
  margin: 0 10px;
}

.body-content {
  min-height: calc(100vh - (56px + 72px));
}

.about {
  /* min-height: 857px; */
  height: auto;
}

/* .our-partners{
  min-height: 305px;
} */
.activity-slider {
  min-height: 400px;
}

.blog-details .article {
  min-height: 105px;
}

#things-to-do .modal-body {
  min-height: 250px;
}

/* .sidebar .hot_badge{
  margin-left: 10px;
} */
.hot_badge {
  /* position: static;
  top: -7px;
  left: -17px; */
  border-radius: 0.375rem;
  box-shadow: rgb(186, 186, 186) 0px 3px 5px;
  padding: 6px 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
  background-color: #35d164;
  text-align: center;
  margin-left: 10px;
}

.react-datepicker-wrapper {
  display: unset !important;
  padding: 0;
  border: 0;
}

@media only screen AND (min-width:768px) AND (max-width:1024px) {
  .list-view .wsk-cp-product .wsk-cp-img img {
    height: 100% !important;
  }
}

/*--------------------------------------------------------------
# Blog Listing Page
--------------------------------------------------------------*/
.blog-card{
  transition: transform .2s;
}
.blog-card:hover{
  transform: translateY(-6px);
}
.blog-card img{
  height: 270px;
  object-fit: cover;
  width: 100%;
}
.blog-card h5{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #212527;
}
.blog-card .blog-footer{

}
.blog-banner{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 175px 0px;
  position: relative;
}
.blog-banner::before {
  background: rgb(0 0 0 / 50%);
  content: "";
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.blog-banner h2{
  position: relative;
  z-index: 1;
  font-size: 40px;
}
.articles_card {
  transition: transform .2s;
  border-bottom:1px solid  #d4d4d4;
  padding-bottom: 20px;
}
.articles_card:hover{
  transform: translateY(-6px);
}
.no-border .articles_card {
  border: none;
}
.articles_card a{
  
}
.articles_card a .article_img{
  width: 25%;
}
.articles_card a .article_img img{
  width: 100%;
  height: 120px;
  object-fit: cover;

}
.articles_card a .article_body{
  width: 75%;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}
.articles_card a .article_body h5{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #212527;
}
.articles_card a .article_body .article_city{
  display: flex;
}
.articles_card a .article_body .article_city p{
  background-color: #d4d4d4;
  border-radius: 15px;
  padding: 2px 6px;
  margin-right: 5px;
  color: #212527;
  font-size: 10px;
}
@media (max-width: 767px) {
  .blog-banner{
    padding: 110px 0px !important;
  }
  .blog-banner h2{
    font-size: 30px;
  }
  .blog-card h5, 
  .articles_card a .article_body h5{
    font-size: 16px;
  }
  .articles_card{
    border-bottom:1px solid  #d4d4d4;
  }
  .articles_card:last-child{
    border-bottom: none;
  }
}

/*--------------------------------------------------------------
# Blog DEtails Page
--------------------------------------------------------------*/
.blog_details{

}
.blog_details .blog_main_content{

}
.blog_details .blog_main_content .blog_title {
  display: flex;
}
.blog_details .blog_main_content .blog_title h2{
  width: 85%;
}
.blog_details .blog_main_content .blog_title .blog_share_modal{
  text-align: right;
  width: 15%;
}
.blog_details .blog_main_content .blog_title .blog_share_modal p{
  font-weight: bold;
  cursor: pointer;
}
.blog_details .blog_main_content .blog_title .blog_share_modal p i{
  color: #28ace2;
  margin-right: 5px;
}
.blog_details .blog_main_content .blog_main_author{
  display: flex;
  align-items: flex-start;
}
.blog_details .blog_main_content .blog_main_author img{
  width: 55px;
  height: 55px;
}
.blog_details .blog_sidebar{
  background-color: var(--color-box-background);
    padding: 24px 16px;
    box-shadow: 0 4px 16px rgba(var(--color-default-rgb), 0.1);
}
.blog_details .blog_sidebar .blog_activity_list .wsk-cp-product a {
  display: flex;
}
.blog_details .blog_sidebar .blog_activity_list button{
  text-transform:none;
}

.slide_bar_activity_list a{
  display: flex;
}
.slide_bar_activity_list a .activity_list_img{
  width: 25%;
}
.slide_bar_activity_list a .activity_list_img img{
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.slide_bar_activity_list a .activity_desc{
  width: 75%;
}
.slide_bar_activity_list a .activity_desc .title-product{
  display: flex;
  justify-content: space-between;
    align-items: center;
}
.slide_bar_activity_list a .activity_desc .title-product h3{
  font-size: 14px;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #212527;
    margin-bottom: 3px;
}
.slide_bar_activity_list a .activity_desc .activity_available p{
  font-size: 10px;
}
.slide_bar_activity_list a .activity_desc .activity_footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slide_bar_activity_list a .activity_desc .activity_footer .price{
  color:#212527;
  font-size: 12px;
  align-items: center;
}
.slide_bar_activity_list a .activity_desc .activity_footer .activity_rating{
  color:#212527;
  font-size: 14px;
}
.slide_bar_activity_list a .activity_desc .activity_footer .activity_rating p i{
  color:#f99f1b;
}
@media (max-width: 767px) {
  .blog_details .blog_main_content .blog_title h2{
    font-size: 20px;
    width: 80%;
  }
  .blog_details .blog_main_content .blog_title .blog_share_modal{
    width: 20%;
  }
}