@media (max-width: 767px){
    section {
        padding: 30px 0!important;
    }
    .section-header p {
        font-size: 24px!important;
    }
    .coupon-card {
        padding: 16px;
    }
    .bg-light {
        background-color: #eee!important;
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15), inset 0 -1px 0 rgba(255,255,255,0.15);
    }
    .coupon-design {
        --img-width: 75px;
        /* display: flex; */
        grid-template-columns: var(--img-width) auto;
        grid-template-rows: 1fr auto;
        grid-gap: 8px;
        gap: 8px;
        align-items: center;
        justify-content: center;
    }
    .ask-faq .coupon-design {
        display: flex;
    }
    .coupon-card h3 {
        font-size: 20px;
        line-height: 26px;
    }
    .coupon-row {
        margin: 0;
        grid-column: 2;
        width: fit-content;
    }
    #cpnBtn {
        padding: 4px 12px;
        font-size: 14px;
        text-align: center;
    }
    .modal-body .search-icons label {
        white-space: inherit;
    }
}
@media (max-width: 1200px) {
    .navbar-nav {
        gap: 8px!important;
    }
    .offcanvas {
        width: auto!important;
        min-width: 270px!important;
    }
    .navbar-nav {
        align-items: flex-start;
    }
    .offcanvas-body {
        padding-top:0;
    }
    .tour-tabs .nav-tabs {
        justify-content: flex-start!important;
    }
    .dropdown-item {
        padding:10px 20px;
    }
}
@media (min-width: 1200px){
    .navbar-expand-xl .offcanvas {
        flex-grow: 0!important;
    } 
    .navbar-expand-xl .navbar-nav .nav-link  {
        margin-left: 16px;
    }
}
  
